/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PagetopCompontswithbg from "./PagetopCompontswithbg";
import banner from "../assets/imagenew/bannervideodoorphone.jpg";
import OurServices from "./OurServices";
import Detailspages from "./Seopages/Seodetailspages";


function Pages({color="white",title,title2,img,header1,header2,mainheading,header3 ,header4 ,header5,header6,mainpara,para1,para2,para3,para4,para5,para6,para7,para8}) {
  return (
    <>
            <div
        className="inner-banner"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="inner-title">
            <h1
              className={` sm:font-bold md:font-bold  sm:text-[90px]  md:text-[90px]   ` }
              style={{ color: color  }}
            >
              {title}
            </h1>
            <ul>
              <li
                className="  font-bold text-[68px] "
                style={{ color: color  }}
              >
                {title2}              </li>
             </ul>
          </div>
        </div>
      </div>
<OurServices />
<section className="bg-white ">
        <h4 className=" w-full h-auto flex justify-center items-center  mt-16">
          {mainheading}
        
        </h4>
        <div className=" w-[80%] mx-auto h-auto flex justify-center items-center  mt-16">
          <p>{mainpara}</p>
        </div>
       <div className="w-[100%]  h-auto flex justify-center items-center ">  <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-1 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl">
             {header1}            </h1>
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul >
                <li>
                 {para1}                </li>
              </ul>
            </div>
          </div>
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl">
             {header2}            </h1>
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul >
                <li>
                {para2} 
                </li>
              </ul>
            </div>
          </div>

          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl">
             {header3}            </h1>
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul >
                <li>
                {para3} 
                </li>
              </ul>
            </div>
          </div>
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl">
             {header4}            </h1>
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul  >
                <li>
                {para4} 
                </li>
              </ul>
            </div>
          </div>
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl">
             {header5}            </h1>
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul>
                <li>
                {para5} 
                </li>
              </ul>
            </div>
            
            <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl">
             {header6}            </h1>
            <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0  ">
              <ul  >
                <li>
                {para6} 
                </li>
              </ul>
            </div>
            
          </div>

        </div></div>
      </section>
      
    </>
  );
}

export default Pages;
